import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Secondary.module.scss'

const b = bem.secondary(css)

/* eslint-disable react/no-danger-with-children */
const Secondary = ({ children, negative, className, dangerouslySetInnerHTML, tag = 'div' }) => {
	const Tag = tag || 'div'
	return (
		<Tag className={b({ negative }, className)} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
			{children}
		</Tag>
	)
}

Secondary.propTypes = {
	negative: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	tag: PropTypes.string,
}

export default Secondary
