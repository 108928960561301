import React from 'react'
import loadable from '@loadable/component'
import './async.scss'

const FeedbackForm = loadable(() => import('./FeedbackForm'))

export default props => (
	<div className="feedback-form-async">
		<FeedbackForm {...props} />
	</div>
)
