import React, { useEffect, useContext } from 'react'
import LocalStorage from 'utils/LocalStorage'
import LinkDuplicate from 'components/LinkDuplicate'
import Button from 'components/Button'
import NotificationsContext from 'contexts/notifications'

/**
 * Инициализация параметра, определяющего показывать ли уведомление о каталоге баз
 */
export const useNotificationCatalogInit = () => {
	if (typeof window === 'undefined') return

	const storage = new LocalStorage('notifications')
	if (typeof storage.get('isShowCatalog') === 'undefined') storage.set('isShowCatalog', true)
	if (window.location.pathname === '/dbs.html') storage.set('isShowCatalog', false)
}

/**
 * Хук, добавляющий уведомление о каталоге баз
 */
export const useNotificationCatalog = (relatedDbs, totalDbs) => {
	const notificationsContext = useContext(NotificationsContext)

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		let timeout
		const storage = new LocalStorage('notifications')
		if (storage.get('isShowCatalog')) {
			timeout = setTimeout(() => {
				storage.set('isShowCatalog', false)
				const id = notificationsContext.add({
					title: 'А у нас еще есть базы',
					text: (
						<div>
							<ul>
								{relatedDbs.slice(0, 2).map(db => (
									<li key={db.name}>
										<LinkDuplicate
											to={db.path}
											title={`Посмотреть базу «${db.title}»`}
											onClick={() => notificationsContext.remove(id)}
										>
											{db.title}
										</LinkDuplicate>
									</li>
								))}
								<li>и еще более {Math.trunc(totalDbs / 10) * 10} шт.</li>
							</ul>
							<p>
								<Button
									isLink
									to="/dbs.html#list"
									title="Открыть полный каталог баз"
									size="xs"
									theme="primary"
									onClick={() => notificationsContext.remove(id)}
								>
									Посмотреть каталог
								</Button>
							</p>
						</div>
					),
					theme: 'light',
				})
			}, 7000)
		}
		return () => clearTimeout(timeout)
	}, [])
}
