import React from 'react'
import PropTypes from 'prop-types'
import { Generic, GenericCollection } from 'react-structured-data'
import genericPerson from './person'

/**
 * Generic организации
 * @param props
 * @returns {*}
 */
const genericOrganization = props => {
	const { site, type } = props
	const { schema, siteUrl } = site

	const {
		name,
		alternateName,
		legalName,
		foundingDate,
		addressCountry,
		addressLocality,
		streetAddress,
		postalCode,
		socials,
	} = schema

	return (
		<Generic
			type={type}
			jsonldtype="Organization"
			schema={{
				name,
				alternateName,
				legalName,
				foundingDate,
				url: siteUrl,
				...(socials && { sameAs: socials.map(v => v.url) }),
				email: schema.emails,
			}}
		>
			<Generic
				type="logo"
				jsonldtype="ImageObject"
				schema={{
					url: siteUrl + '/s/schema/logo.png',
					width: 360,
					height: 392,
				}}
			/>
			<Generic
				type="address"
				jsonldtype="PostalAddress"
				schema={{ addressCountry, addressLocality, streetAddress, postalCode }}
			/>
			<GenericCollection type="founders">
				{schema.founders.map(name => genericPerson({ key: name, name }))}
			</GenericCollection>
			<GenericCollection type="contactPoint">
				{schema.telephones.map(telephone => (
					<Generic
						key={telephone}
						jsonldtype="ContactPoint"
						schema={{
							telephone,
							contactType: 'customer service',
							areaServed: schema.addressCountry,
							availableLanguage: 'Russian',
						}}
					/>
				))}
			</GenericCollection>
		</Generic>
	)
}

// TODO: props
genericOrganization.propTypes = {
	type: PropTypes.string.isRequired,
}

export default genericOrganization
