import React from 'react'
import bem from 'bem'
import css from './Header.module.scss'
import Link from 'components/LinkDuplicate'
import Content from 'components/Content'
import HeaderMenu from './components/Menu'
import useSiteMetadata from 'hooks/useSiteMetadata'
import Icon from 'components/Icon'
import Call from 'components/Call'

const b = bem.header(css)

const Header = () => {
	const { title } = useSiteMetadata()
	return (
		<div className={b()}>
			<Content>
				<div className={b('content')}>
					<Link className={b('logo')} to="/" rel="home" title={title}>
						<Icon type="logo" />
					</Link>
					<div className={b('contacts')}>
						<Call />
					</div>
					<div className={b('menu')}>
						<HeaderMenu />
					</div>
				</div>
			</Content>
		</div>
	)
}

export default Header
