import React from 'react'
import PropTypes from 'prop-types'
import css from './Content.module.scss'
import bem from 'bem'
import WithClassName from 'containers/WithClassName'

const b = bem.content(css)

const Content = ({ children, noPadding, className, ...rest }) => (
	<div className={b({ 'is-no-padding': noPadding }, className)} {...rest}>
		{children}
	</div>
)

Content.propTypes = {
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	noPadding: PropTypes.bool,
}

export default WithClassName(Content)
