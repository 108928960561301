import { graphql, useStaticQuery } from 'gatsby'

const MENUS_QUERY = graphql`
	fragment MenuItemFields on WPGraphQL_MenuItem {
		id
		label
		url
		target
		acf {
			external
			icon
		}
		connectedObject {
			__typename
		}
	}

	query GET_MENUS {
		wpgraphql {
			menus(first: 1000000) {
				edges {
					node {
						name
						menuId
						acf {
							weight
						}
						menuItems(first: 1000000) {
							nodes {
								...MenuItemFields
								childItems(first: 1000000) {
									nodes {
										...MenuItemFields
										childItems(first: 1000000) {
											nodes {
												...MenuItemFields
												childItems(first: 1000000) {
													nodes {
														...MenuItemFields
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

/**
 * Меню по ids
 */
const useMenusByIds = ids => {
	const data = useStaticQuery(MENUS_QUERY)
	let menus = data.wpgraphql.menus.edges || []
	menus = menus.filter(menu => ids.indexOf(menu.node.menuId) !== -1)
	menus.sort((a, b) => a.node.acf.weight - b.node.acf.weight)
	return menus
}

export default useMenusByIds
