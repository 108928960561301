import React, { useState } from 'react'
import bem from 'bem'
import css from './HeaderMenu.module.scss'
import MenuItem from './components/Item'
import MenuToggle from './components/Toggle'
import useMenusByIds from 'hooks/useMenusByIds'
import useEscape from 'hooks/useEscape'
import WithLocation from 'containers/WithLocation'

const b = bem.headerMenu(css)

const HeaderMenu = props => {
	const [active, setActive] = useState(false)
	useEscape(() => setActive(false))

	const menus = useMenusByIds([3])
	if (!menus.length) return null
	const menu = menus[0]

	const { location } = props

	// Замена ссылки в меню Отзывы на хеш для страниц баз компаний
	const reviewsItem = menu.node.menuItems.nodes.find(item => item.label.indexOf('Отзывы') !== -1)
	if (location.pathname.indexOf('/db-') !== -1) {
		reviewsItem.url = location.pathname + '#reviews'
		reviewsItem.connectedObject.__typename = 'hash'
	} else {
		reviewsItem.url = '/reviews'
		reviewsItem.connectedObject.__typename = 'WPGraphQL_MenuItem'
	}

	return (
		<nav className={b()}>
			<div className={b('toggle')}>
				<MenuToggle active={active} onClick={() => setActive(!active)} />
			</div>
			<ul className={b('items', { active })}>
				<MenuItem className={b('home')} item={{ childItems: { nodes: [] }, label: 'Главная', url: '/' }} />
				{menu.node.menuItems.nodes.map(item => (
					<MenuItem key={item.label} item={item} />
				))}
			</ul>
		</nav>
	)
}

export default WithLocation(HeaderMenu)
