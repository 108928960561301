import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './HeaderMenuItem.module.scss'
import MenuSub from '../Sub'
import Link from 'components/Link'

const b = bem.headerMenuItem(css)

const HeaderMenuItem = ({ item, depth = 0, className }) => {
	// Имеет ли текущее меню вложенное меню
	const isChild = !!(item.childItems && item.childItems.nodes.length)
	return (
		<li className={b({ depth: depth + 1 }, className)} key={item.label}>
			<Link className={b('link', { 'is-child': isChild })} item={item}>
				{item.label}
			</Link>
			<div className={b('child')}>
				<MenuSub subItems={item.childItems.nodes} depth={depth + 1} />
			</div>
		</li>
	)
}

HeaderMenuItem.propTypes = {
	item: PropTypes.shape({
		childItems: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
		}).isRequired,
		label: PropTypes.string.isRequired,
	}).isRequired,
	depth: PropTypes.number,
}

export default HeaderMenuItem
