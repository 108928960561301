import initModules from './modules'

let root = {
	layoutMain: null,

	sceneHome: null,
	sceneHomeDevices: null,
	sceneParsingAds: null,
	sceneCart: null,
	sceneCartPromo: null,

	scenePage: null,
	scenePosts: null,
	scenePost: null,
	sceneCategory: null,
	sceneTag: null,
	sceneUser: null,
	scenePartner: null,
	scenePartnerProfit: null,
	scenePartnerHow: null,
	scenePartnerCalc: null,

	pageEmpty: null,

	header: null,
	headerMenu: null,
	headerMenuSub: null,
	headerMenuItem: null,
	headerMenuToggle: null,

	footer: null,
	footerMenu: null,
	footerMenus: null,

	postEntryShort: null,
	postEntryLeft: null,
	postEntryRight: null,

	postThumbnail: null,
	postDate: null,
	postCategories: null,
	postTags: null,
	postAuthor: null,
	postTitle: null,
	postExcerpt: null,
	postRelativeList: null,

	adaptersError: null,
	adaptersHelp: null,
	adapterText: null,
	adapterTextarea: null,
	adapterCheckbox: null,
	adapterRadio: null,
	adapterSlider: null,

	heading: null,
	calc: null,
	calc2: null,
	content: null,
	sector: null,
	loop: null,
	tag: null,
	mark: null,
	tags: null,
	breadcrumbs: null,
	icon: null,
	linkDuplicate: null,
	call: null,
	helperButton: null,
	button: null,
	buttonCart: null,
	pagination: null,
	modal: null,
	break: null,
	slider: null,
	presentation: null,
	feature: null,
	features: null,
	explanations: null,
	cube: null,
	examples: null,
	example: null,
	work: null,
	works: null,
	service: null,
	ruble: null,
	contacts: null,
	accent: null,
	logos: null,
	listIcons: null,
	secondary: null,
	feedback: null,
	feedbackForm: null,
	map: null,
	scrollToTop: null,
	monitor: null,
	formMessage: null,
	toc: null,
	share: null,
	card: null,
	stepper: null,
	subscribe: null,
	faq: null,
	accordion: null,
	cookies: null,
	banner: null,
	bannerDbCompany: null,
	bannerTest: null,
	table: null,
	tableList: null,
	tableLight: null,
	columns: null,
	dbList: null,
	dbPreview: null,
	dbIframe: null,
	gallery: null,
	figure: null,
	triangle: null,
	author: null,
	textIcon: null,
	product: null,

	notifications: null,
	notification: null,

	comment: null,
	commentList: null,

	shoppingButton: null,
	shoppingPopup: null,

	reviews: null,
	review: null,
}

const bem = initModules(root)

export default bem
