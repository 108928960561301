import React from 'react'
import { JSONLD } from 'react-structured-data'
import genericOrganization from 'components/Schema/Generic/organization'
import useSiteMetadata from 'hooks/useSiteMetadata'

/**
 * Схема - Организация
 * @returns {JSX.Element}
 * @constructor
 */
const SchemaOrganization = () => {
	const site = useSiteMetadata()
	return <JSONLD dangerouslyExposeHtml>{genericOrganization({ site, type: 'seller' })}</JSONLD>
}

export default SchemaOrganization
