import React from 'react'
import PropTypes from 'prop-types'
import { Generic } from 'react-structured-data'

/**
 * Generic персоны
 * @param props
 * @returns {*}
 */
const genericPerson = props => {
	const { key, type, name, url } = props
	return <Generic key={key} type={type} jsonldtype="Person" schema={{ name, url }} />
}

// TODO: props
genericPerson.propTypes = {
	type: PropTypes.string,
}

export default genericPerson
