// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/tree/master?tab=readme-ov-file

const kebabCase = s =>
	s &&
	s
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		.map(x => x.toLowerCase())
		.join('-')

const pickBy = (obj, func) => {
	const res = {}
	for (const i in obj) {
		if (obj.hasOwnProperty(i) && func(obj[i], i)) {
			res[i] = obj[i]
		}
	}
	return res
}

const debounce = (func, wait, immediate) => {
	var timeout
	return function() {
		var context = this,
			args = arguments
		clearTimeout(timeout)
		timeout = setTimeout(function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}, wait)
		if (immediate && !timeout) func.apply(context, args)
	}
}

const random = (a = 1, b = 0) => {
	const lower = Math.min(a, b)
	const upper = Math.max(a, b)
	return lower + Math.random() * (upper - lower)
}

const randomInt = (a = 1, b = 0) => {
	const lower = Math.ceil(Math.min(a, b))
	const upper = Math.floor(Math.max(a, b))
	return Math.floor(lower + Math.random() * (upper - lower + 1))
}

const map = (object, iteratee) => {
	object = Object(object)
	const result = {}

	const isFunction = typeof iteratee === 'function'
	Object.keys(object).forEach(key => {
		result[key] = isFunction ? iteratee(object[key], key, object) : object[key][iteratee]
	})
	return Object.values(result)
}

const uniq = array => [...new Set(array)]

const chunk = (input, size) => {
	return input.reduce((arr, item, idx) => {
		return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]]
	}, [])
}

const upperFirst = string => {
	return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

const lowerFirst = string => {
	return string ? string.charAt(0).toLowerCase() + string.slice(1) : ''
}

const camelCase = string => {
	return lowerFirst(
		string
			.toLowerCase()
			.split(/[\s-_]/)
			.filter(Boolean)
			.map(upperFirst)
			.join('')
	)
}

const startCase = string => {
	return string
		.replace(/([a-zа-я])([A-ZА-Я])/g, '$1 $2')
		.split(/[\s-_]/)
		.filter(Boolean)
		.map(upperFirst)
		.join(' ')
}

const shuffle = array => {
	return array.sort(() => Math.random() - 0.5)
}

const groupBy = (arr, criteria) => {
	return arr.reduce(function(obj, item) {
		let key = typeof criteria === 'function' ? criteria(item) : item[criteria]
		if (!Object.prototype.hasOwnProperty.call(obj, key)) {
			obj[key] = []
		}
		obj[key].push(item)
		return obj
	}, {})
}

const flatten = array => {
	return array.flat()
}

const sortBy = (arr, key) => {
	const _sort = key => (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)
	return arr.concat().sort(_sort(key))
}

module.exports = {
	kebabCase,
	pickBy,
	debounce,
	random,
	randomInt,
	map,
	uniq,
	chunk,
	upperFirst,
	lowerFirst,
	camelCase,
	startCase,
	shuffle,
	groupBy,
	flatten,
	sortBy,
}
