import React from 'react'

/**
 * Оборачивает компонент в div с переданным классом при наличии
 * @param Component
 * @returns {function(...[*]=)}
 * @constructor
 */
const WithClassName = Component => ({ className, classNameOutside, ...props }) => {
	if (classNameOutside)
		return (
			<div className={classNameOutside}>
				<Component {...props} />
			</div>
		)
	return <Component className={className} {...props} />
}

export default WithClassName
