import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Modal.module.scss'
import Icon from 'components/Icon'
import useEscape from 'hooks/useEscape'

const b = bem.modal(css)

const Modal = ({ visible, handleClose, children }) => {
	useEscape(handleClose)

	useEffect(() => {
		const method = visible ? 'add' : 'remove'
		document.body.classList[method]('no-scroll')
	}, [visible])

	if (!visible) return false
	return (
		<div className={b()}>
			<div className={b('overlay')} onClick={handleClose} onKeyDown={() => {}} role="button" tabIndex={0} />
			<div className={b('wrapper')}>
				<div className={b('inner', 'scrollbar-styled')}>
					<div className={b('content')}>
						<button className={b('close-button')} type="button" title="Закрыть" onClick={handleClose}>
							<Icon type="close" />
						</button>
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

Modal.propTypes = {
	visible: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
}

export default Modal
