import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './TextIcon.module.scss'
import LinkDuplicate from 'components/LinkDuplicate'
import Icon from 'components/Icon'

const b = bem.textIcon(css)

const TextIcon = ({ icon, iconSize, iconColor, textColor, children, className, ...rest }) => {
	const Tag = rest.to ? LinkDuplicate : 'div'

	return (
		<Tag className={b(null, className)} {...rest}>
			<Icon className={b('icon')} type={icon} size={iconSize} color={iconColor} />
			<span
				className={b('text')}
				style={{
					...(textColor && { color: textColor }),
				}}
			>
				{children}
			</span>
		</Tag>
	)
}

TextIcon.defaultProps = {
	iconSize: 24
};

TextIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number,
	iconColor: PropTypes.string,
	textColor: PropTypes.string,
	to: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
	target: PropTypes.oneOf(['_blank']),
}

export default TextIcon
