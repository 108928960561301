import { memo } from 'react'
import objectsDiff from 'utils/objectsDiff'
import objectClearedForMemo from 'utils/objectClearedForMemo'

/**
 * Стандартная оптимизация без функций и объектов
 * Необходима для предотвращения бесконечного обновления компонента при использовании loadable внутри
 * @param Component
 * @returns {React.NamedExoticComponent<object>}
 */
const memoize = Component => {
	return memo(Component, (prevProps, nextProps) => {
		const prev = objectClearedForMemo(prevProps)
		const next = objectClearedForMemo(nextProps)
		return !objectsDiff(prev, next)
	})
}

export default memoize
