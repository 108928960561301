import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './ButtonCart.module.scss'
import Icon from 'components/Icon'
import LinkDuplicate from 'components/LinkDuplicate'
import StoreContext from 'contexts/store'
import usePrevious from 'hooks/usePrevious'

const b = bem.buttonCart(css)

const ButtonCart = props => {
	const [buttonAnimated, setButtonAnimated] = useState(false)
	const [counterAnimated, setCounterAnimated] = useState(false)
	const storeContext = useContext(StoreContext)
	const count = props.count || storeContext.data.databases.filter(v => v.name || !v.loading).length
	const prevCount = usePrevious(count)

	// Использование ref для сохранения текущего значения таймаута при обновлении
	const timeoutRef = useRef(null)

	// Анимация всей кнопки
	useEffect(() => {
		if (prevCount === 0 && count === 1) {
			setButtonAnimated(true)
		}
	}, [count, prevCount])

	// Анимация счетчика
	useEffect(() => {
		if (prevCount !== null && prevCount !== count) {
			setCounterAnimated(true)
			// Очищаем, если предыдущий таймаут не успел выполниться
			clearTimeout(timeoutRef.current)
			timeoutRef.current = setTimeout(() => setCounterAnimated(false), 1000)
		}
	}, [count, prevCount, timeoutRef])

	// Анимация счетчика - удаление таймаута после размонтирования компонента
	// Не делаем это в верхнем useEffect, так как функция очистки выполняется каждый раз при изменении deps
	useEffect(() => {
		return () => clearTimeout(timeoutRef.current)
	}, [])

	if (!count) return null

	return (
		<div className={b({ buttonAnimated })}>
			<LinkDuplicate className={b('link')} to="/cart.html" title="Перейти в корзину">
				<Icon type="cartFill" />
			</LinkDuplicate>
			{count && <span className={b('counter', { counterAnimated })}>{count}</span>}
		</div>
	)
}

ButtonCart.propTypes = {
	count: PropTypes.number,
}

export default ButtonCart
