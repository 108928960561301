import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './FooterMenu.module.scss'
import Link from 'components/Link'
import Icon from 'components/Icon'

const b = bem.footerMenu(css)

const FooterMenu = ({ className, title, items }) => {
	return (
		<div className={b({}, className)}>
			<div className={b('title')}>{title}</div>
			<ul className={b('items')}>
				{items.map(({ icon, child }, index) => {
					return (
						<li key={`footer-menu-${index}`} className={b('item')}>
							{icon && (
								<div className={b('icon')}>
									<Icon type={icon} />
								</div>
							)}
							<div className={b('links')}>
								{child.map((item, index) => (
									<Link key={`footer-menu-child-${index}`} className={b('link')} item={item}>
										<span dangerouslySetInnerHTML={{ __html: item.label }} />
									</Link>
								))}
							</div>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

FooterMenu.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.string,
			child: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string.isRequired,
					url: PropTypes.string,
					external: PropTypes.bool,
					target: PropTypes.oneOf(['_blank']),
					connectedObject: PropTypes.shape({
						__typename: PropTypes.string.isRequired,
					}),
				}).isRequired
			).isRequired,
		}).isRequired
	).isRequired,
}

export default FooterMenu
