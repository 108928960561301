import React from 'react'
import bem from 'bem'
import css from './FooterMenus.module.scss'
import { Row, Col } from 'react-flexbox-grid'
import FooterMenu from '../Menu'
import useMenusByIds from 'hooks/useMenusByIds'

const b = bem.footerMenus(css)

// Отбор меню по id
const filter = (menus, ids) => {
	return menus.filter(menu => ids.indexOf(menu.node.menuId) !== -1)
}

// Формирование объекта данных для вывода меню
const getChild = ({ label, url, target, connectedObject, acf: { external } }) => ({
	label,
	url,
	external,
	target,
	connectedObject,
})

// const FooterMenus2 = () => {
// 	const menus = useMenusByIds([4, 6])
// 	if (!menus.length) return null
//
// 	const menuMain = menus.find(v => v.node.menuId === 4)
// 	const menuContacts = menus.find(v => v.node.menuId === 4)
//
// 	console.log(menuMain);
// 	console.log(menus);
// 	// let grouped = [filter(menus, [5, 6, 7, 8]), filter(menus, [4])]
//
// 	return (
// 		<div className={b()}>
// 			<Row>
// 				<Col>
// 					<FooterMenu
// 						className={b('menu')}
// 						title={menu.name}
// 						items={menu.menuItems.nodes.map(node => {
// 							const {
// 								acf: { icon },
// 								childItems: { nodes },
// 							} = node
//
// 							const child = nodes.length ? nodes.map(getChild) : [getChild(node)]
//
// 							return { icon, child }
// 						})}
// 					/>
// 				</Col>
// 			</Row>
// 		</div>
// 	)
// }

const FooterMenus = () => {
	const menus = useMenusByIds([4, 6, 7])
	// const menus2 = useMenusByIds([4, 5, 6, 7, 8])
	if (!menus.length) return null

	// let grouped = [filter(menus, [5, 6, 7, 8]), filter(menus, [4])]
	let grouped = [filter(menus, [4, 6, 7])]

	const adaptives = {
		4: { xs: 12, sm: 12, md: 5 }, // контакты
		6: { xs: 12, sm: 4, md: 3 }, // о нас
		7: { xs: 12, sm: 4, md: 4 }, // парсинг
	}

	return (
		<div className={b()}>
			{grouped.map((group, index) => (
				<Row key={index} between="xs">
					{group.map(({ node: menu }) => {
						return (
							<Col key={menu.menuId} {...adaptives[menu.menuId]}>
								<FooterMenu
									className={b('menu')}
									title={menu.name}
									items={menu.menuItems.nodes.map(node => {
										const {
											acf: { icon },
											childItems: { nodes },
										} = node

										const child = nodes.length ? nodes.map(getChild) : [getChild(node)]

										return { icon, child }
									})}
								/>
							</Col>
						)
					})}
				</Row>
			))}
		</div>
	)
}

// const FooterMenus = () => {
// 	const menus = useMenusByIds([4, 5, 6, 7, 8])
// 	if (!menus.length) return null
//
// 	let grouped = [filter(menus, [5, 6, 7, 8]), filter(menus, [4])]
//
// 	return (
// 		<div className={b()}>
// 			{grouped.map((group, index) => (
// 				<Row key={index} between="xs">
// 					{group.map(({ node: menu }) => {
// 						const len = group.length
// 						const xs = len === 1 ? 12 : 6
// 						const sm = Math.floor(12 / len)
// 						return (
// 							<Col key={menu.menuId} xs={xs} sm={sm}>
// 								<FooterMenu
// 									className={b('menu')}
// 									title={menu.name}
// 									items={menu.menuItems.nodes.map(node => {
// 										const {
// 											acf: { icon },
// 											childItems: { nodes },
// 										} = node
//
// 										const child = nodes.length ? nodes.map(getChild) : [getChild(node)]
//
// 										return { icon, child }
// 									})}
// 								/>
// 							</Col>
// 						)
// 					})}
// 				</Row>
// 			))}
// 		</div>
// 	)
// }

export default FooterMenus
