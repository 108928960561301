import lodash from 'utils/lodash'

/**
 * Объект без функций
 * @param object
 * @returns {{}}
 */
const objectClearedForMemo = object => {
	const res = {}
	lodash.map(object, (value, key) => {
		if (['function', 'object'].indexOf(typeof value) === -1) res[key] = value
	})
	return res
}

export default objectClearedForMemo
