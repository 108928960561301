import React from 'react'
import bem from 'bem'
import css from './Call.module.scss'
import Icon from 'components/Icon'
import FeedbackButton from 'components/FeedbackButton'

const b = bem.call(css)

const Call = () => {
	return (
		<div className={b()}>
			<FeedbackButton component={'button'} className={b('icon')} type="button" title="Связаться с нами">
				<Icon type="phone" />
			</FeedbackButton>
			<FeedbackButton component={'div'} className={b('phone')} title="Связаться с нами" role="button" tabIndex={0}>
				+7 (999) 700-22-01
			</FeedbackButton>
		</div>
	)
}

export default Call
