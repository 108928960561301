import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Triangle.module.scss'

const b = bem.triangle(css)

const Triangle = ({ children, top, right, bottom, left, color }) => {
	return <div className={b({ top, right, bottom, left, color })}>{children}</div>
}

Triangle.propTypes = {
	children: PropTypes.any,
	top: PropTypes.bool,
	right: PropTypes.bool,
	bottom: PropTypes.bool,
	left: PropTypes.bool,
	color: PropTypes.oneOf(['white', 'gray', 'blue', 'purple', 'purple-lightness', 'cyan', 'green', 'orange']),
}

export default Triangle
