import React from 'react'
import PropTypes from 'prop-types'
import getUrlSlug from 'utils/getUrlSlug'
import route from 'utils/route'
import LinkDuplicate from 'components/LinkDuplicate'

/**
 * Ссылка в контексте темы
 * @param to
 * @param rest
 * @returns {null|*}
 * @constructor
 */
const Link = ({ item, children, ...rest }) => {
	const { url, title, target, connectedObject = {}, acf = {} } = item
	const external = item.external || acf.external
	let type = connectedObject.__typename || ''
	type = type.replace('WPGraphQL_', '').toLowerCase()
	if (['category', 'tag', 'post', 'page', 'hash'].indexOf(type) === -1) type = 'custom'
	let linkTitle = title || children
	linkTitle = typeof linkTitle === 'object' ? linkTitle.props.dangerouslySetInnerHTML.__html : linkTitle

	if (url === '#') return <span {...rest}>{children}</span>

	if (external)
		return (
			<a href={url} title={linkTitle} rel="nofollow noopener noreferrer" target={target} {...rest}>
				{children}
			</a>
		)

	let to = url

	if (to !== '/') {
		const slug = getUrlSlug(url, type)
		to = route[type]({ slug })
	}

	return (
		<LinkDuplicate to={to} title={linkTitle} {...rest}>
			{children}
		</LinkDuplicate>
	)
}

Link.propTypes = {
	item: PropTypes.shape({
		url: PropTypes.string.isRequired,
		title: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
		external: PropTypes.bool,
		target: PropTypes.oneOf(['_blank']),
		connectedObject: PropTypes.shape({
			__typename: PropTypes.string,
		}),
	}).isRequired,
}

export default Link
