import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Icon.module.scss'
import loadable from '@loadable/component'
import memoize from 'utils/memoize'

const b = bem.icon(css)

const Icon = ({ type, size, color, className }) => {
	// with memoize
	const Svg = loadable(() => import(`./img/${type}.svg`))
	if (!Svg) return null

	return (
		<i
			className={b({}, className)}
			style={{
				...(size && { width: size, height: size }),
				...(color && { color }),
			}}
		>
			<Svg />
		</i>
	)
}

Icon.propTypes = {
	type: PropTypes.string.isRequired,
	size: PropTypes.number,
	color: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default memoize(Icon)
