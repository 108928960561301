import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Notification.module.scss'
import Icon from 'components/Icon'

const b = bem.notification(css)

const Notification = props => {
	const { id, title, text, onRemove, theme } = props
	return (
		<div className={b({ theme })}>
			<div className={b('title')}>{title}</div>
			{text && <div className={b('text')}>{text}</div>}
			<button type="button" className={b('close')} title="Закрыть" onClick={() => onRemove(id)}>
				<Icon type="close" />
			</button>
		</div>
	)
}

Notification.defaultProps = {
	theme: 'default',
}

Notification.propTypes = {
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onRemove: PropTypes.func.isRequired,
	theme: PropTypes.oneOf(['default', 'primary', 'light']),
}

export default Notification
