import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Notifications.module.scss'
import Notification from './components/Notification'
import { useNotificationCatalogInit } from 'hooks/useNotificationCatalog'

const b = bem.notifications(css)

const Notifications = props => {
	// Инициализация показа уведомления о каталоге баз
	useNotificationCatalogInit()

	const { items, onRemove } = props
	if (!items || !items.length) return null

	return (
		<div className={b()}>
			{items.map(item => (
				<Notification key={item.id} onRemove={onRemove} {...item} />
			))}
		</div>
	)
}

Notifications.propTypes = {
	items: PropTypes.array,
	onRemove: PropTypes.func.isRequired,
}

export default Notifications
