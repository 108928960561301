import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import useSiteMetadata from 'hooks/useSiteMetadata'
import WithLocation from 'containers/WithLocation'
import ogImage from './img/og-image.png'
import getImageType from 'utils/getImageType'

const SEO = props => {
	const { title, description, noindex, keywords, canonical, location, alternate = [], og = {} } = props
	const siteMetadata = useSiteMetadata()
	const metaTitle = title || siteMetadata.title
	const metaDescription = description || siteMetadata.description

	const openGraph = {
		title,
		description,
		image: {
			url: siteMetadata.siteUrl + ogImage,
			width: 1018,
			height: 453,
		},
		type: 'website',
		locale: 'ru_RU',
		siteName: siteMetadata.title,
		url: siteMetadata.siteUrl + location.pathname,
		...og,
	}

	openGraph.image.secureUrl = openGraph.image.url
	openGraph.image.insecureUrl = openGraph.image.url ? openGraph.image.url.replace('https', 'http') : null
	openGraph.image.type = getImageType(openGraph.image.url)

	return (
		<Helmet defer={false} htmlAttributes={{ lang: 'ru' }} title={metaTitle} titleTemplate={`%s`}>
			<title>{metaTitle}</title>
			<meta name="description" content={metaDescription} />
			{keywords && <meta name="keywords" content={keywords} />}
			{noindex && <meta name="robots" content="noindex, nofollow" />}

			<link rel="canonical" href={siteMetadata.siteUrl + (canonical || location.pathname)} />

			{alternate.map(({ lang, href }) => (
				<link rel="alternate" hrefLang={lang || 'x-default'} href={siteMetadata.siteUrl + href} />
			))}

			{/* Open Graph */}
			{openGraph.title && <meta property="og:title" content={openGraph.title} />}
			{openGraph.description && <meta property="og:description" content={openGraph.description} />}

			{openGraph.image.secureUrl && <meta property="og:image:secure_url" content={openGraph.image.secureUrl} />}
			{openGraph.image.insecureUrl && <meta property="og:image" content={openGraph.image.insecureUrl} />}
			{openGraph.image.type && <meta property="og:image:type" content={openGraph.image.type} />}
			{openGraph.image.width && <meta property="og:image:width" content={openGraph.image.width} />}
			{openGraph.image.height && <meta property="og:image:height" content={openGraph.image.height} />}

			{openGraph.type && <meta property="og:type" content={openGraph.type} />}
			{openGraph.url && <meta property="og:url" content={openGraph.url} />}
			{openGraph.siteName && <meta property="og:siteName" content={openGraph.siteName} />}
			{openGraph.locale && <meta property="og:locale" content={openGraph.locale} />}
		</Helmet>
	)
}

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	keywords: PropTypes.string,
	canonical: PropTypes.string,
	noindex: PropTypes.bool,
	alternate: PropTypes.arrayOf(
		PropTypes.shape({
			lang: PropTypes.oneOf(['ru', 'en', 'x-default']),
			href: PropTypes.string.isRequired,
		}).isRequired
	),
	og: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		image: PropTypes.shape({
			url: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired,
		}),
		type: PropTypes.string,
		locale: PropTypes.string,
		siteName: PropTypes.string,
		url: PropTypes.string,
	}),
}

export default WithLocation(SEO)
