import React from 'react'
import PropTypes from 'prop-types'
import css from './HeaderMenuToggle.module.scss'
import bem from 'bem'

const b = bem.headerMenuToggle(css)

const HeaderMenuToggle = ({ active, className, ...rest }) => {
	return (
		<button className={b({ active }, className)} {...rest}>
			<i className={b('icon')} />
			Меню
		</button>
	)
}

HeaderMenuToggle.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default HeaderMenuToggle
