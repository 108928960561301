import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Feedback.module.scss'
import Modal from 'components/Modal'
import FeedbackForm from 'components/FeedbackForm'
import FeedbackContext from 'contexts/feedback'
import TextIcon from 'components/TextIcon'

const b = bem.feedback(css)

const Feedback = ({ isVisible }) => {
	const feedback = useContext(FeedbackContext)
	return (
		<div className={b()}>
			<Modal visible={isVisible} handleClose={feedback.close}>
				<div className="mb-lg text-center">
					<p className="h1 mb-xs">
						<a className="link-light" href="tel:+79997002201">
							<strong>+7 (999) 700-22-01</strong>
						</a>
					</p>
					<p className="fs-h3 mb">
						<a className="link-light" href="mailto:info@parsic.ru" title="Написать на электронную почту">
							info@parsic.ru
						</a>
					</p>
					<ul className={b('items')}>
						<li>
							<TextIcon
								icon="telegram"
								iconColor="white"
								textColor="white"
								to="https://telegram.me/iparsic"
								title="Написать в Telegram"
								target="_blank"
							>
								<strong>Telegram</strong>
							</TextIcon>
						</li>
						<li>
							<TextIcon
								icon="whatsappEmpty"
								iconColor="white"
								textColor="white"
								to="https://api.whatsapp.com/send?phone=79997002201"
								title="Написать в WhatsApp"
								target="_blank"
							>
								<strong>WhatsApp</strong>
							</TextIcon>
						</li>
						<li>
							<TextIcon
								icon="vk"
								iconSize={28}
								iconColor="white"
								textColor="white"
								to="https://vk.com/parsic_ru"
								title="Написать в ВКонтакте"
								target="_blank"
							>
								<strong>ВКонтакте</strong>
							</TextIcon>
						</li>
					</ul>
				</div>
				<FeedbackForm
					url="call"
					title={
						<div className="mb text-center">
							<p className="fs-h3 mb-0">Оставьте свой номер</p>
							<p>и мы вам перезвоним</p>
						</div>
					}
					success={
						<div>
							<p className="fs-h3">Сообщение отправлено</p>
							<p>Мы свяжемся с вами в ближайшее время</p>
						</div>
					}
					fields={{
						name: { required: true },
						phone: { required: true },
						message: { required: false },
					}}
					isNegative
				/>
			</Modal>
		</div>
	)
}

Feedback.propTypes = {
	isVisible: PropTypes.bool,
}

export default Feedback
