const mimes = {
	jpg: 'image/jpeg',
	png: 'image/png',
	webp: 'image/webp',
	gif: 'image/gif',
}

/**
 * Определение mime-типа изображения по URL
 * @param src
 */
const getImageType = src => {
	const ext = src.substr(src.lastIndexOf('.') + 1)
	return mimes[ext]
}

export default getImageType
