import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import FeedbackContext from 'contexts/feedback'

const FeedbackButton = ({ component: Component, ...rest }) => {
	const feedback = useContext(FeedbackContext)
	return <Component {...rest} onClick={feedback.open} onKeyDown={() => {}} />
}

FeedbackButton.defaultProps = {
	component: Button,
}

FeedbackButton.propTypes = {
	component: PropTypes.any,
}

export default FeedbackButton
