const category = ({ slug, number }, isFirstSlash = true) => {
	let url = ''
	if (isFirstSlash) url += '/'
	url += `category/${slug}`
	if (number > 1) url += `/page/${number}`
	url += `.html`
	return url
}

const tag = ({ slug, number }, isFirstSlash = true) => {
	let url = ''
	if (isFirstSlash) url += '/'
	url += `tag/${slug}`
	if (number > 1) url += `/page/${number}`
	url += `.html`
	return url
}

const user = ({ slug, number }, isFirstSlash = true) => {
	let url = ''
	if (isFirstSlash) url += '/'
	url += `author/${slug}`
	if (number > 1) url += `/page/${number}`
	url += `.html`
	return url
}

const post = ({ slug }, isFirstSlash = true) => {
	let url = ''
	if (isFirstSlash) url += '/'
	url += `post/${slug}`
	url += `.html`
	return url
}

const page = ({ slug }, isFirstSlash = true) => {
	let url = ''
	if (isFirstSlash) url += '/'
	url += slug
	url += `.html`
	return url
}

const custom = ({ slug, number = 0 }, isFirstSlash = true) => {
	if (slug === '/' && number < 2) return '/'
	if (slug === '/' && number >= 2) return `${isFirstSlash ? '/' : ''}page/${number}.html`

	let url = ''
	if (isFirstSlash) url += '/'
	url += slug
	if (number > 1) url += `/page/${number}`
	url += `.html`
	url = url.replace(/\/{2,}/, '/')
	return url
}

const hash = ({ slug }) => {
	return slug
}

module.exports = { category, tag, user, post, page, custom, hash }
