import React, { useState } from 'react'
import FeedbackContext from 'contexts/feedback'

const WithFeedbackContext = Component => {
	const WithFeedbackComponent = props => {
		const [visible, setVisible] = useState(false)
		const context = {
			open: () => setVisible(true),
			close: () => setVisible(false),
		}

		return (
			<FeedbackContext.Provider value={context}>
				<Component {...props} feedbackIsVisible={visible} />
			</FeedbackContext.Provider>
		)
	}
	return WithFeedbackComponent
}

export default WithFeedbackContext
