import React from 'react'
import bem from 'bem'
import css from './Heading.module.scss'
import Content from 'components/Content'

const b = bem.heading(css)

const Heading = ({ title, description }) => {
	const margin = !description ? 'mb-0' : undefined
	return (
		<div className={b()}>
			<Content>
				<h1 className={margin}>{title}</h1>
				{description && <div className="fs-h4" dangerouslySetInnerHTML={{ __html: description }} />}
			</Content>
		</div>
	)
}

export default Heading
