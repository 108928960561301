import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './HeaderMenuSub.module.scss'
import MenuItem from '../Item'

const b = bem.headerMenuSub(css)

const HeaderMenuSub = ({ subItems, depth = 0 }) => {
	if (!subItems.length) return null
	return (
		<div className={b({ depth })}>
			<ul className={b('list')}>
				{subItems.map(item => (
					<MenuItem key={item.id} item={item} depth={depth} />
				))}
			</ul>
		</div>
	)
}

HeaderMenuSub.propTypes = {
	subItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	depth: PropTypes.number.isRequired,
}

export default HeaderMenuSub
