const { default: block } = require('bem-cn-lite')
const lodash = require('../utils/lodash')

/**
 * Реализация через css modules
 * @param list
 * @returns {{}}
 */
const initModules = (list = {}) => {
	const res = {}
	for (const name of Object.keys(list)) {
		res[name] = css => {
			return function() {
				const b = block(name)
				let rules = b(...arguments).split(' ')

				rules = rules.map(v => {
					// Класс уже модульный
					if (v.indexOf('--') !== -1) return v

					// Класс содержит элемент
					if (v.indexOf('__') !== -1) {
						const parts = v.split('__')
						parts[1] = parts[1].replace(/_/g, '%')
						parts[1] = lodash.camelCase(parts[1])
						parts[1] = parts[1].replace(/%/g, '_')
						v = parts.join('__')
					}

					// Класс содержит модификатор
					else if (v.indexOf('_') !== -1) {
						const parts = v.split('_')
						if (parts[1]) parts[1] = lodash.camelCase(parts[1])
						v = parts.join('_')
					}

					return v
				})

				const output = rules.map(v => css[v] || v)
				return output.join(' ')
			}
		}
	}
	return res
}

module.exports = initModules
