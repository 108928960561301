import React from 'react'
import bem from 'bem'
import css from './Footer.module.scss'
import Content from 'components/Content'
import { Grid, Row, Col } from 'react-flexbox-grid'
import FooterMenus from './components/Menus'
import Link from 'gatsby-link'
import route from 'utils/route'

const b = bem.footer(css)

const Footer = () => {
	return (
		<div className={b()}>
			<Content>
				<Grid fluid>
					<Row>
						<Col lg={10} lgOffset={1}>
							<FooterMenus />
							<Row>
								<Col xs={12} sm={6} smOffset={3}>
									<hr className={b('line')} />
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={b('copyright')}>
										<p>
											2018 © Парсик
											<br />
											Все права защищены
										</p>
										<p>
											<Link to={route.custom({ slug: '/privacy' })} title="Политика обработки персональных данных">
												Политика обработки персональных данных
											</Link>
										</p>
										<p>
											<Link
												to={route.custom({ slug: '/payment-and-return' })}
												title="Порядок предоставления товаров и услуг и возврат денежных средств"
											>
												Порядок предоставления товаров и услуг и возврат
											</Link>
										</p>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Grid>
			</Content>
		</div>
	)
}

export default Footer
