import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Button.module.scss'
import Icon from 'components/Icon'
import LinkDuplicate from 'components/LinkDuplicate'

const b = bem.button(css)

const Button = props => {
	const {
		className,
		children,
		type,
		theme,
		size,
		icon,
		full,
		loading,
		isLink,
		isLinkNative,
		to,
		isNoBorder,
		relative,
		bold,
		...rest
	} = props
	let Component = 'button'
	if (isLink) Component = LinkDuplicate
	if (isLinkNative) Component = 'a'
	const params = {
		className: b(
			{ size, full, bold, type: theme, 'no-content': !children, 'is-no-border': isNoBorder, relative },
			className
		),
		...rest,
		...(isLink && { to }),
		...(isLinkNative && { href: to }),
		...(!isLink && !isLinkNative && { type }),
	}
	return (
		<Component {...params}>
			{(icon || loading) && (
				<Icon className={b('icon', { 'is-single': !children })} type={loading ? 'loading' : icon} />
			)}
			{children}
		</Component>
	)
}

Button.propTypes = {
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.string,
	theme: PropTypes.oneOf(['default', 'primary', 'warning', 'danger', 'success', 'light', 'link', 'gold']),
	size: PropTypes.oneOf(['xs', 'sm', 'lg']),
	icon: PropTypes.string,
	full: PropTypes.bool,
	bold: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	isLink: PropTypes.bool, // Является ссылкой gatsby
	isLinkNative: PropTypes.bool, // Является нативной ссылкой <a...
	to: PropTypes.string,
	isNoBorder: PropTypes.bool,
	relative: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
}

Button.defaultProps = {
	type: 'button',
	theme: 'default',
}

export default Button
